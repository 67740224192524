@import "../../styles/_variables.scss";

.doc-sign-content {
  margin: 5rem 0 3.5rem;
  background: whitesmoke;
  padding: 0.5rem;
}

.disclaimer {
  font-size: 0.75rem;
  text-align: center;
  user-select: none;
}

hr {
  background: $brand-secondary;
}

// .doc-info {
//   border-bottom: 1px solid $brand-secondary;
// }

.docSignBox {
  background: whitesmoke;
  padding: 0.5rem;
  user-select: none;
  // width: 95%;
  // height: 95%;
}

.sigCanvas {
  border: 1px solid black;
  background-color: white;
  height: 145px;
  width: 100%;
}
