@import "../../styles/variables.scss";

.header {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  text-align: center;
  border-bottom: 3px solid $brand-secondary;

  // ------ Comment out this code if we want to add "DocSign" to header
  // display: flex;
  // justify-content: space-around;
  // font-family: "Times New Roman", Times, serif;
  // color: $brand-primary;

  h1 {
    font-weight: 500;
  }
}

.header-img {
  height: 50px;
  width: 200px;
}
