@import "../../styles/variables.scss";

.footer {
  background-color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  border-top: 3px solid $brand-secondary;
  /* clear: both; */
}

.footer-img {
  height: 25px;
  width: 125px;
  margin: 0.5em 0;
}
