body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  text-align: center;
  border-bottom: 3px solid #caaa1c; }
  .header h1 {
    font-weight: 500; }

.header-img {
  height: 50px;
  width: 200px; }

.footer {
  background-color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  border-top: 3px solid #caaa1c;
  /* clear: both; */ }

.footer-img {
  height: 25px;
  width: 125px;
  margin: 0.5em 0; }

.doc-sign-content {
  margin: 5rem 0 3.5rem;
  background: whitesmoke;
  padding: 0.5rem; }

.disclaimer {
  font-size: 0.75rem;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

hr {
  background: #caaa1c; }

.docSignBox {
  background: whitesmoke;
  padding: 0.5rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.sigCanvas {
  border: 1px solid black;
  background-color: white;
  height: 145px;
  width: 100%; }

/* .App {
  text-align: center;
  margin-top: 5%;
} */
* {
  box-sizing: border-box; }

html {
  min-width: 100vw;
  position: relative;
  height: 100%;
  overflow-x: hidden; }

body {
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #9e9e9e), color-stop(100%, #4d4d4d));
  background: cover;
  background-attachment: fixed; }

.main-content {
  padding-right: 0; }
  .main-content h1 {
    font-size: calc(16px + 1.5vmin);
    text-decoration: underline; }

.content-container {
  padding: 1rem;
  margin: 1rem 0;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 10px 3px white;
  -moz-box-shadow: 0 0 10px white;
  -webkit-box-shadow: 0 0 10px white; }

.content-container {
  width: 100%; }

.content .form-group {
  max-width: 20rem;
  margin: 0.5rem auto; }

.content .form-control {
  border: 2px solid #0000c2;
  height: 1.5rem;
  padding-bottom: 0.55rem; }

.content .form-control::-webkit-input-placeholder {
  font-size: 0.75rem;
  padding-bottom: 0.25rem; }

.content .form-control::-ms-input-placeholder {
  font-size: 0.75rem;
  padding-bottom: 0.25rem; }

.content .form-control::placeholder {
  font-size: 0.75rem;
  padding-bottom: 0.25rem; }

.content .form-control:focus {
  outline: none;
  border: 3px solid #caaa1c;
  box-shadow: 0px 0px 5px 0.1rem #64009c;
  -moz-box-shadow: 0 0 10px #64009c;
  -webkit-box-shadow: 0 0 10px #64009c; }

.form-group label {
  font-weight: 600;
  margin-bottom: 0; }

.custom-btn {
  background: radial-gradient(#eee243 30%, #aa8b00 100%);
  padding: 0.5em 1.5em;
  border: none;
  border-radius: 0.25em;
  color: #002357;
  box-shadow: 2px 2px 5px #002357;
  font-size: 0.65rem;
  font-weight: 600; }

.custom-btn:active {
  transform: translateY(4px);
  box-shadow: none; }

.custom-btn:focus {
  outline: none;
  border: 3px solid black;
  box-shadow: none; }

.custom-btn:hover {
  background-image: radial-gradient(#ecc526 15%, #bb741e 90%); }

