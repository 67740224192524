@import "../src/styles/variables";

/* .App {
  text-align: center;
  margin-top: 5%;
} */

* {
  box-sizing: border-box;
}

html {
  min-width: 100vw;
  position: relative;
  height: 100%;
  overflow-x: hidden;
}

body {
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(50%, #9e9e9e),
    color-stop(100%, #4d4d4d)
  );
  background: cover;
  background-attachment: fixed;
  // margin-right: -16px;
}

// ===============
// Structure
// ===============

// .mainContainer {
//   height: 100vh;
//   display: flex;
//   flex-direction: column;
// }

// .content-row {
//   margin-top: 2.75rem;
// }

.main-content {
  padding-right: 0;

  h1 {
    font-size: calc(16px + 1.5vmin);
    text-decoration: underline;
  }
}

.content-container {
  padding: 1rem;
  margin: 1rem 0;

  // ------ use sass -----
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 10px 3px white;
  -moz-box-shadow: 0 0 10px white;
  -webkit-box-shadow: 0 0 10px white;
}

// .content {
//   // ----- use sass -----
//   box-shadow: 0px 0px 10px 3px white;
//   -moz-box-shadow: 0 0 10px white;
//   -webkit-box-shadow: 0 0 10px white;

//   // ----- use sass -----
//   border: 3px solid $brand-primary;
//   border-top: 20px solid $brand-primary;
//   padding-bottom: 1rem;
//   background: white;
//   border-radius: 0.5rem;
// }

// ------ Alt title style -------
// .main-content h1 {
//   color: $brand-primary;
//   font-size: 3rem;
//   margin: .5rem;

//   u {
//     text-decoration: none;
//   }
// }

// END STRUCTURE

// =====================
// Form Style
// =====================

.content-container {
  width: 100%;
}

.content .form-group {
  max-width: 20rem;
  margin: 0.5rem auto;
}

.content .form-control {
  border: 2px solid $brand-primary;
  height: 1.5rem;
  padding-bottom: 0.55rem;
}

.content .form-control::placeholder {
  // color: $brand-primary;
  font-size: 0.75rem;
  padding-bottom: 0.25rem;
}

.content .form-control:focus {
  outline: none;
  border: 3px solid $brand-secondary;

  // ----- use sass -----
  box-shadow: 0px 0px 5px 0.1rem #64009c;
  -moz-box-shadow: 0 0 10px #64009c;
  -webkit-box-shadow: 0 0 10px #64009c;
}

.form-group label {
  // color: $brand-primary;
  font-weight: 600;
  margin-bottom: 0;
  // font-size: 0.75rem;
  // letter-spacing: 0.05rem;
}

// form button {
//   margin-top: 1rem;
//   margin-right: 1rem;
// }
// _____________________________________

// =====================
// Custom Button
// =====================
.custom-btn {
  background: radial-gradient(#eee243 30%, #aa8b00 100%);
  padding: 0.5em 1.5em;
  border: none;
  border-radius: 0.25em;
  color: $brand-primary-dark;
  box-shadow: 2px 2px 5px $brand-primary-dark;
  font-size: 0.65rem;
  font-weight: 600;
}

.custom-btn:active {
  transform: translateY(4px);
  box-shadow: none;
}

.custom-btn:focus {
  outline: none;
  border: 3px solid black;
  // box-shadow: 3px 3px 5px #525252;
  box-shadow: none;
}

.custom-btn:hover {
  background-image: radial-gradient(#ecc526 15%, #bb741e 90%);
}
// End Custom Button
